.filters-and-results-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

h4 {
    margin: 0; /* Resets margin */
    padding: 0; /* Resets padding */
    font-weight: 300;
}

.search-header-and-close {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.search-header-and-filter-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.map-and-close {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.map-button {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    border: solid;
    border-width: 1px;
    border-radius: 25px;
    padding: 6px 10px 6px 10px;
    border-style: solid;
    border-color: #E4E4E4;
    font-size: 11px;
}

.map-button p {
    margin-bottom: 0px;
}

.map-button img{
    height: 20px;
    width: 20px;
}

.close-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid;
    border-width: 1px;
    border-radius: 50%;
    padding: 6px;
    border-style: solid;
    border-color: #E4E4E4;
    height: 40px;
    width: 40px;
}

.close-button img{
    height: 20px;
    width: 20px;
}