@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';

.home-page-container {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    padding: 25px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.core-home-contents {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.location-and-chapter {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.location-and-chapter h2 {
    margin: 0; /* Resets margin */
    padding: 0; /* Resets padding */
}

.location-and-chapter h3 {
    margin: 0; /* Resets margin */
    padding: 0; /* Resets padding */
    font-weight: 300;
}

.play-like-share {
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically centers the items */
    justify-content: flex-start; /* Aligns items to the left */
    gap: 15px;
}

.play-and-timer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.timer {
    display: flex;
    align-items: center;
}