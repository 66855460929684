.share-button-container {
    height: 40px;
    width: 40px;
}

.share-button {
    height: 100%;
    width: 100%;
    border-style: solid;
    border-color: #E4E4E4;
    border-width: 1px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.share-button img{
    height: 20px;
    width: 20px;
}