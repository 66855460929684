@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';

.location-header {
    padding: 10px;
    min-height: 40px;
    background: white;
    justify-content: left;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-color: #E4E4E4;
    border-radius: 10px;
}

.location-header-title {
    margin: 0;
    margin-bottom: 1px;
    font-size: 14px;
}

.location-header h2 {
    margin: 0;
}

.location-header h3 {
    margin: 0;
}

.location-options {
    padding: 10px;
}

.location-option {
    padding-top: 10px;
    padding-bottom: 10px;
}

.location-and-down-arrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.down-arrow {
    margin-top: 7px;
}