.like-button-container {
    height: 40px;
    width: 40px;
}

.like-button {
    height: 100%;
    width: 100%;
    border-style: solid;
    border-color: #E4E4E4;
    border-width: 1px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding-top: 9px;
}

.like-icon {
    height: 18px;
    width: 18px;
}