@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';
/* TEST */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  background-color: white;
  /* padding: 25px; */
  height: 100vh; /* Full viewport height */
  box-sizing: border-box; /* Include padding in the height calculation */
  overflow: hidden; /* Prevent scrolling on the main container */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer 10+ and Edge */
  scrollbar-width: none; /* Hide scrollbar for modern Firefox */
}

.App::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers like Chrome and Safari */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}