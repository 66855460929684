.filter-results {
    display: flex;
    flex-direction: column;
    max-height: 400px; /* Set the desired height */
    overflow-y: auto;
}

.location-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.location-metadata-item {
    padding: 3px 10px 3px 10px;
    border-style: solid;
    border-color: #E4E4E4;
    border-width: 1px;
    border-radius: 20px;
    font-size: 11px;
    margin-bottom: 0px;
}

.title {
    font-weight: bold;
}