.progress-bar {
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
}

.progress {
    height: 100%;
    background-color: black;
    transition: width 0.1s linear;
}

.progress-circle {
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: left 0.1s linear;
}

.progress-bar-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-color: #E4E4E4;
    border-width: 1px;
    border-radius: 25px;
    padding: 10px;
}

.progress-bar-timer > * {
    margin: 0; /* Reset margin for all direct children */
    padding: 0; /* Reset padding for all direct children */
}