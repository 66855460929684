.location-metadata {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.location-metadata-item {
    padding: 3px 10px 3px 10px;
    border-style: solid;
    border-color: #E4E4E4;
    border-width: 1px;
    border-radius: 20px;
    font-size: 11px;
}

.chapter-item {
    background-color: #FAF0FF;
    border: none;
}