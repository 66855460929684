.play-pause-button {
    height: 45px;
    width: 45px;
    background-color: black;
    border-radius: 50%;
    margin: auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play-button-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.play-button {
    height: 20px;
    width: 20px;
    margin-left: 8px;
}

.pause-button {
    height: 20px;
    width: 20px;
    margin-left: 2px;
}