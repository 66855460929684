.category-and-filter-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.category-and-filter-options h4 {
    font-weight: bold;
}


.filters {
    display: flex;
    flex-wrap: wrap;
}

.filter {
    padding: 3px 10px 3px 10px;
    border-style: solid;
    border-color: #E4E4E4;
    border-width: 1px;
    border-radius: 15px;
    font-size: 12px;
}

.filter:not(:first-child) {
    margin-left: 5px;
}

.selected {
    color: white;
    background-color: black;
}

h3 {
    margin: 0; /* Resets margin */
    padding: 0; /* Resets padding */
}