.chapters-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.chapter p {
    font-weight: 300;
}

.inactive {
    color: #C8C8C8;
}